import { DEFAULT_REQUEST_HEADER, STORE_HEADER_SERVICES_URL, TEXT_HTML_REQUEST_HEADER } from "../../common/store-header-services-constants";
import { handleAutoContextFail, handleAutoContextSuccess } from "../autocontextualization.src";
export function getAutoContextHtml(successCallback, errorCallback) {
  lm.fetch({
    url: STORE_HEADER_SERVICES_URL + "/autocontextualization" + "/refresh",
    method: 'GET',
    credentials: 'same-origin',
    headers: TEXT_HTML_REQUEST_HEADER,
    success: successCallback,
    error: errorCallback
  });
}
export function autoContextUserFromStoreIdAndPostCode(storeId, postCode) {
  const data = {
    storeId
  };
  if (postCode) data.postcode = postCode;
  lm.fetch({
    url: STORE_HEADER_SERVICES_URL + "/autocontextualization" + "/customer-context",
    data,
    method: 'GET',
    credentials: 'same-origin',
    headers: DEFAULT_REQUEST_HEADER,
    success: handleAutoContextSuccess,
    error: handleAutoContextFail
  });
}
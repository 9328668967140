import { STORE_HEADER_SERVICES_URL } from "../../common/store-header-services-constants";
export function fetchAutoContextToasterService(successCallback) {
  lm.fetch({
    url: STORE_HEADER_SERVICES_URL + '/storeheader/auto-context-toaster',
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'text/html',
      'Content-Type': 'text/html; charset=UTF-8',
      'Cache': 'no-cache',
      'Access-Control-Allow-Origin': '*'
    },
    success: successCallback
  });
}
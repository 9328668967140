import { STORE_HEADER_SERVICES_URL, TEXT_HTML_REQUEST_HEADER } from "../../common/store-header-services-constants";
export function getContextHeaderHtml(successCallback, errorCallback) {
  lm.fetch({
    url: STORE_HEADER_SERVICES_URL + "/contextheader" + "/refresh",
    method: 'GET',
    credentials: 'same-origin',
    headers: TEXT_HTML_REQUEST_HEADER,
    success: successCallback,
    error: errorCallback
  });
}
import { EventBus } from '@adeo/kobi--front-helpers';
import { onUserConsentChange } from "../autocontextualization/autocontextualization.src";
import { autoContextUserFromStoreIdAndPostCode } from "../autocontextualization/services/auto-context-service";
export const STORE_HEADER_MODULE_EVENT_BUS_NAME = "store-module-header-event-bus";
export const OPEN_STORE_CONTEXT_LAYER_EVENT = "openStoreContextLayer";
export const OPENING_STORE_CHOICES_MODAL_EVENT = "autocontext:choicestore:openmodal";
export const SET_STORE_EVENT = "autocontext:store:set";
export const USER_CONSENT_EVENT = "PrivacyChange";
export const USER_AUTO_CONTEXT_SUCCESS_EVENT = 'userAutoContextSuccess';
initStoreHeaderEventBus();
listenUserConsentEvent();
const eventBus = window.storeHeaderModule.eventBus;
function initStoreHeaderEventBus() {
  if (!window.storeHeaderModule) {
    window.storeHeaderModule = {};
  }
  if (!window.storeHeaderModule.eventBus) {
    window.storeHeaderModule.eventBus = new EventBus(STORE_HEADER_MODULE_EVENT_BUS_NAME);
  }
}
function listenUserConsentEvent() {
  window.addEventListener(USER_CONSENT_EVENT, onUserConsentChange);
}
export function dispatchCustomerContextSuccessEvent() {
  const event = new CustomEvent(USER_AUTO_CONTEXT_SUCCESS_EVENT, {
    detail: {
      isUserAutoContextSuccess: true
    }
  });
  window.dispatchEvent(event);
}
export function dispatchCustomerAutoContextFailureEvent() {
  const event = new CustomEvent(USER_AUTO_CONTEXT_SUCCESS_EVENT, {
    detail: {
      isUserAutoContextSuccess: false
    }
  });
  window.dispatchEvent(event);
}
export function dispatchOpenStoreContextLayerEvent() {
  // TODO: Use eventBus instead and see if wait for the layer to be ready before opening it.
  setTimeout(() => {
    window.dispatchEvent(new Event(OPEN_STORE_CONTEXT_LAYER_EVENT));
  }, 3000);
}
export function dispatchOpeningStoreChoicesModalEvent() {
  eventBus.emit(OPENING_STORE_CHOICES_MODAL_EVENT);
}
eventBus.on(SET_STORE_EVENT, ({
  detail
}) => {
  autoContextUserFromStoreIdAndPostCode(detail.storeId, detail.postcode);
});